import { Injectable } from '@angular/core';
import { CommonQueriesService } from 'src/app/common-queries.service';
import { CREATE_AUDIT_LOG } from '../../gql/query';

@Injectable({
  providedIn: 'root'
})
export class AuditHistoryService {

  constructor(
    public commonQueryService: CommonQueriesService,
  ) { }


  auditHistoryForCreateOrDeleteCall(auditLogPayload) {
    auditLogPayload['createdAt'] = "now()";
    auditLogPayload['newData'] = encodeURIComponent(JSON.stringify(auditLogPayload.newData));

    return new Promise((resolve, reject) => {

      let auditLogFinalPayload = {
        input: {
          auditLog: auditLogPayload
        }
      };

      this.commonQueryService.mutationQuery(CREATE_AUDIT_LOG, auditLogFinalPayload).subscribe((resonseLog) => {
      });

    })

  }


}
