export const aboutConst = {
    aboutHeader1: 'About Us',
    aboutHeader2: 'The Infamous Home Inspection',
    aboutHeader3: 'Why SpeKwo',
    aboutHeader4: 'Our Team',
    aboutContent1:
        'speKwo was in the making for over 20 years. The leadership team invested the better part of that time buying, fixing, and selling houses; doing remodels for realtors and investors; and most importantly through those two activities had the opportunity to work through over 2,000 inspection reports. This experience led them to the belief that while home inspections are prudent, they also put transactions at risk.',
    aboutContent2:
        'Buyers and sellers often do not understand it when an inspector flags an item to “be assessed by a professional contractor”, and more than that, they have a difficult time agreeing on what that item may cost. To further complicate matters, they must work within a due diligence timeline and wrangle in numerous different contractors to get quotes from and then hopefully come to an agreement on what the cost is and which party is going to absorb it.',
    aboutContent3:
        'Our founders believed there was a better way. They understood the value of home inspections and the frustration of contractors. They believed there was a need for a neutral party providing consistent pricing for items discovered on home inspection reports. They wanted to provide clarity and assurance for items that sellers don’t want to pay for and buyers are worried about. From that understanding, speKwo was created.',
    aboutContent4:
        'Our office is staffed with experts in every field. Our team has extensive experience in building and remodeling homes. We have reviewed thousands of inspection reports. You have the option of scheduling a call to simply gain insight into your home inspection or you have the option of getting a quote on any single part or your entire home inspection report. While not all problems are exactly the same and cost of labor and materials vary slightly from state to state, we are confident we can give you accurate pricing to assist you in your next steps.',
};
