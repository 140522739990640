<div class="service-article">
    <div class="service-article-img">
        <img [src]="productsImages[productConst.sku]" alt="Images">
    </div>

    <div class="service-article-content">
        <h2>{{productConst.productName}}</h2>
        <p [innerHTML]="productConst.productDesc1"></p>
    </div>

</div>