<!-- Start Inner Banner -->
<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Area -->
<div class="service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Services</span>
            <h2>We Always Ready to Serve You the Best Service</h2>
            <p>We have a good expert in the global market and this will help us to make a good image on our service market and globally.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-wheel'></i>
                    </a>
                    <h3><a routerLink="/service-details">Wheel Adjustment</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-settings'></i>
                    </a>
                    <h3><a routerLink="/service-details">Engine Servicing</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-fan'></i>
                    </a>
                    <h3><a routerLink="/service-details">Cooling Servicing</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-disc-brake'></i>
                    </a>
                    <h3><a routerLink="/service-details">Brake Service</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-car-service-1'></i>
                    </a>
                    <h3><a routerLink="/service-details">Washing Service</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/service-details" class="service-icon">
                        <i class='flaticon-racing'></i>
                    </a>
                    <h3><a routerLink="/service-details">Tyre Service</a></h3>
                    <p>We are always help to make one of the best adjustment service for us and all the details.</p>
                    <a routerLink="/service-details" class="read-more-btn">
                        <i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i>
                    </a>
                </div>
            </div>
           
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/services-1" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services-1" class="page-numbers">2</a>
                    <a routerLink="/services-1" class="page-numbers">3</a>
                    <a routerLink="/services-1" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Area End -->