import { gql } from "apollo-angular";

export const list_products_without_offset = gql`query allProducts{
  allProducts(filter: {isDeletedYn: {equalTo: false}}){
    nodes{
      nodeId
      productId
      productName
      productPrice
      productDesc1
      isDeletedYn
      createdAt
      updatedAt
      sku
    }
  }
}`;

export const list_products_by_sku = gql`query allProducts($sku: String) {
  allProducts(filter: {isDeletedYn: {equalTo: false}, sku: {equalTo: $sku}}) {
    nodes {
      nodeId
      productId
      productName
      productPrice
      productDesc1
      isDeletedYn
      createdAt
      updatedAt
      sku
    }
  }
}`;


export const list_testimonials = gql`query BannerReviews {
  allBannerReviews(filter: {isDeletedYn: {equalTo: false}}) {
    nodes {
      bannerReviewId
      imageUrl
      description
    }
    totalCount
  }
}
`;
