<div class="service-details-widget">
    <h3 class="title">Contact Us</h3>

    <div class="row pt-20">
        <div class="col-lg-12 col-md-4">
            <div class="service-widget-card">
                <i class='bx bx-home-smile'></i>
                <div class="content">
                    <h3>{{contactConst.label.address}}</h3>
                    <p>{{contactConst.officeAddress1}},</p>
                    <span>{{contactConst.officeAddress2}}</span>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-4">
            <div class="service-widget-card">
                <i class='bx bx-phone-call'></i>
                <div class="content">
                    <h3>{{contactConst.label.phone}}</h3>
                    <p><a href="tel: 866.646.0200">{{contactConst.contactNumber}}</a></p>
                    <!-- <span><a href="tel:+215-123-8597">+215-123-8597</a></span> -->
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-4">
            <div class="service-widget-card">
                <i class='bx bxs-envelope'></i>
                <div class="content">
                    <h3>{{contactConst.label.email}}</h3>
                    <p><a href="mailto:info@spekwo.com">{{contactConst.officeEmail}}</a></p>
                    <!-- <span><a href="mailto:hello@qeni.com">hello@qeni.com</a></span> -->
                </div>
            </div>
        </div>
    </div>
</div>