import { gql } from "apollo-angular";

export const list_markets_with_limit_offset = gql`query Markets($offset:Int,$limit:Int) {
    allMarkets(offset:$offset,first:$limit, filter: {isDeletedYn : {equalTo: false}}) {
      nodes {
        marketId
        marketName
        isDeletedYn
        createdat
        updatedat
      }
      totalCount
    }
  }`;

export const list_firm_with_limit_offset = gql`query MarketsMultiFirm($offset: Int, $limit: Int, $marketId: Int) {
  allMarketsMultiFirms(offset: $offset, first: $limit, filter: {isDeletedYn: {equalTo: false}, marketId: {equalTo: $marketId}}) {
    nodes {
      marketsMultiFirmsId
      marketId
      marketByMarketId {
        marketName
      }
      firmId
      firmByFirmId {
        firmsName
      }
    }
    totalCount
  }
}
`;

export const list_office_with_limit_offset = gql`query FirmsMultiOffices($offset: Int, $limit: Int, $firmId: Int, $marketId: Int) {
  allFirmsMultiOffices(
    offset: $offset
    first: $limit
    filter: {isDeletedYn: {equalTo: false}, firmId: {equalTo: $firmId}, marketId: {equalTo: $marketId}}
  ) {
    nodes {
      firmsMultiOfficesId
      officeId
      firmId
      marketId
      officeByOfficeId {
        officeId
        officesName
      }
      firmId
      firmByFirmId {
        firmId
        firmsName
      }
      isDeletedYn
      createdat
      updatedat
    }
    totalCount
  }
}`;

