import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { homeConst } from '../../../constants';

import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-testimonial',
    templateUrl: './testimonial.component.html',
    styleUrls: ['./testimonial.component.scss'],
})
export class TestimonialComponent implements OnInit {
    formValues = homeConst;
    allTestimonials = homeConst.testimonialContent;

    constructor(private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        public router: Router,
    ) {
        this.getTestimonialsList();
    }

    ngOnInit(): void {
    }

    getTestimonialsList() {
        this.authService.testimonialsArray.subscribe((response) => {
            if (response && response.length > 0) {
                this.allTestimonials = response;
                this.changeDetectorRef.detectChanges();
                // this.router.navigate(['/page-refresh'])
                // setTimeout(() => {
                //     this.router.navigate([''])
                // }, 500);
            }
        })
    }
}
