<div class="container">
    <div class="row mb-12">
        <img class="col-lg-4 col-md-12 col-sm-12" [src]="productsImages[sku]" alt="Images">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <p> <span class="properties">{{orderSubmitConst.propertyAddress}}</span> <span>{{propertyAddress}}</span>
            </p>
            <p><span class="properties">{{orderSubmitConst.yourOrderId}}</span> <span>{{orderId}}</span></p>
            <p>{{orderSubmitConst.thankYou + estimateHour + " " + orderSubmitConst.thankYouEnd}} </p>
            <p>{{orderSubmitConst.orderDesc + estimateHour + " " + orderSubmitConst.orderDesc1}}</p>
            <p>{{orderSubmitConst.orderDesc4}}</p>
            <p class="properties">{{ orderSubmitConst.orderDesc3 + " " + productName + " " + orderSubmitConst.orderDesc2
                + dueDate}}
            </p>
            <p *ngIf="sku === productsSku.preList">{{orderSubmitConst.toMeet}}
            </p>
        </div>
    </div>
    <p class="col-lg-8 col-md-12 col-sm-12 title">{{orderSubmitConst.quiz}}</p>
    <p class="col-lg-8 col-md-12 col-sm-12 title fun-quiz">{{orderSubmitConst.funQuiz}}</p>
    <div class="quiz-section">
        <div class="order-submit" [formGroup]="calculationForm" class="col-lg-8 col-md-12 col-sm-12">
            <p>{{ orderSubmitConst.diffContractors }}
                <input matInput size="5" formControlName="phoneCallQue" />
            </p>
            <p>{{ orderSubmitConst.meetContractors }}
                <input matInput size="5" formControlName="constratorQue" />

            </p>
            <p>{{ orderSubmitConst.howManyTimeCall }}
                <input matInput size="5" formControlName="quoteQue" />

            </p>
            <p class="result" *ngIf="isCalcaulate">{{ calculationForm.value.total ? orderSubmitConst.savedTime +
                calculationForm.value.total +
                orderSubmitConst.savedTime1 : ""}}</p>
            <p *ngIf="isCalcaulate" class="result">
                &nbsp;&nbsp;&nbsp;{{calculationForm.value.phoneCallAns}}&nbsp;&nbsp;hours of
                phone calls</p>
            <p *ngIf="isCalcaulate" class="result">
                &nbsp;&nbsp;&nbsp;{{calculationForm.value.constratorAns}}&nbsp;&nbsp;hours of
                driving around</p>
            <p *ngIf="isCalcaulate" class="result">
                &nbsp;&nbsp;&nbsp;{{calculationForm.value.quoteAns}}&nbsp;&nbsp;minutes of
                following up and stressing over DD
                timelines.</p>
            <p *ngIf="isCalcaulate" class="result">Keep up the good work! </p>
            <p *ngIf="isCalcaulate" class="quote result">{{ orderSubmitConst.timeAndEffect1 }}</p>
            <p *ngIf="!isCalcaulate" class="quote">{{ orderSubmitConst.timeAndEffort }}</p>
            <button type="submit" class="order-default-btn fl" (click)="onGoBack()">{{ orderSubmitConst.goBack
                }}</button>

        </div>
        <div class="row align-items-center p-24 col-lg-4 col-md-12 col-sm-12 calculate-btn">
            <button class="order-default-btn calculate" type="submit" (click)="calculate()">{{
                orderSubmitConst.calculate }}</button>
            <button class="order-default-btn btn play-again" type="submit" (click)="reCalculate()">{{
                orderSubmitConst.reCalculate
                }}</button>
        </div>
    </div>


</div>

<!-- <div class="about-area  pb-45">
    <div class="container">
        <app-testimonial></app-testimonial>
    </div>
</div>
<div>
    <app-spekwo-served-images></app-spekwo-served-images>
</div> -->