import { gql } from "apollo-angular";

export const update_order = gql`
mutation updateOrder($input: UpdateOrderByIdInput!){
    updateOrderById(input: $input){
      order{
        id
        orderContent
        archive
        orderedDate
        createdAt
        firstName
        lastName
        city
        state
        postcode
        address
        homeinsurl
        ddraurl
        agentrole
        indrole
        productId
        supportReportUrl
        uniqueReportUrl
        wdourl
        structengurl
        wellurl
        clientphone
        clientemail
        duedate
        notes
        marketId
        firmId
        officeId
        couponId
        statusId
        contactName
        contactDate
        contactEmail
        contactPhone
        projectInfo
        haveMorePics
        longitude
        latitude
      }
    }
  }`;

export const create_order = gql`
mutation createOrder($input: CreateOrderInput!){
  createOrder(input: $input){
    order{
			id
      orderContent
      archive
      orderedDate
      createdAt
      firstName
      lastName
      city
      state
      postcode
      address
      homeinsurl
      ddraurl
      agentrole
      indrole
      productId
      supportReportUrl
      uniqueReportUrl
      wdourl
      structengurl
      wellurl
      clientphone
      clientemail
      duedate
      notes
      marketId
      firmId
      officeId
      couponId
      statusId
      contactName
      contactDate
      contactEmail
      contactPhone
      projectInfo
      haveMorePics
      longitude
      latitude
    }
  }
}`;

export const create_invoice = gql`
mutation createInvoice($input: CreateStripeinvoiceInput!){
  createStripeinvoice(input: $input){
    stripeinvoice{
      orderId
      invoiceObject
      chargeObject
      invoiceid
      id
    }
  }
}`;

export const list_non_expire_coupon = gql`
query Coupons($couponCode: String!, $expireDate: Date!) {
  allCoupons(filter: {isDeletedYn: {equalTo: false}, couponCode: {equalTo: $couponCode}, expireDate: {greaterThanOrEqualTo: $expireDate}}) {
    nodes {
      couponId
      couponCode
      couponDesc
      couponAmount
      usageLimit
      totalLimit
      expireDate
      isDeletedYn
      createdat
      updatedat
    }
    totalCount
  }
}`;

export const update_coupon = gql`mutation updateCoupons($input: UpdateCouponByCouponIdInput!) {
  updateCouponByCouponId(input: $input) {
    coupon {
      couponId
      couponCode
      couponDesc
      couponAmount
      usageLimit
      totalLimit
      expireDate
      isDeletedYn
      createdat
      updatedat
    }
  }
}`;

export const list_status_by_name = gql`
  query allStatusesbyName {
    allStatuses(filter: {statusName: {equalTo: "initiate"}}) {
      nodes {
        statusId
        statusName
        displayStatus
      }
    }
  }`;


export const get_orders_with_all_markets = gql`
query getOrders($offset: Int, $limit: Int) {
  allOrders(offset: $offset, first: $limit, orderBy: ID_DESC, filter: {firmId: {isNull: false}, archive: {isNull: true}}) {
    nodes {
      id
      marketId
      archive
      marketByMarketId {
        marketName
      }
      firmId
      firmByFirmId {
        firmsName
        logoUrl
      }
      orderContent
      agentrole
    }
    totalCount
  }
}`;

export const get_schedules_with_all_markets = gql`
query listSchedulerequests {
  listSchedulerequests {
    pageInfo {
      hasNextPage
    }
    nodes {
      id
      orderId
      createdAt
      statusChangeAt
      statusId
      inspectionReportsSrcByScheduleRequestId {
        orderId
        orderByOrderId {
          marketId
          marketByMarketId {
            marketName
          }
          firmId
          firmByFirmId {
            firmsName
            logoUrl
          }
          orderContent
          agentrole
        }
      }
    }
    totalCount
  }
}`;



export const get_breaking_news = gql`query breakingNews($offset: Int, $limit: Int) {
  allBreakingNews(offset: $offset, first: $limit, filter: {isActiveYn: {equalTo: true}, isDeletedYn: {equalTo: false}}, orderBy: BREAKING_NEWS_ID_DESC) {
    nodes {
      nodeId
      breakingNewsId
      newsMessage
      isActiveYn
      isDeletedYn
    }
  }
}`;

