<!-- Start Inner Banner -->
<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Pricing</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Pricing Area -->
<div class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Pricing Plan</span>
            <h2>We Are Always Ready to Give You the Best Price Benefits</h2>
            <p>We have a good expert in the global market and this will help us to make a good image on our service market and globally.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg1">
                        <i class="bx bx-paper-plane pricing-icon color-1"></i>
                        <h3 class="color-1">STANDARD</h3>

                        <div class="price-rate">
                            <h2 class="color-1">$ 69.99</h2>
                            <span class="color-1">Weekly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i> Weekly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i> Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i> Unlimited Servicing.</li>
                            <li><del>Unlimited Parts Replacement.</del></li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg2">
                        <i class="bx bx-paper-plane pricing-icon color-2"></i>
                        <h3 class="color-2">ECONOMY</h3>

                        <div class="price-rate">
                            <h2 class="color-2">$ 79.99</h2>
                            <span class="color-2">Monthly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i> Monthly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i> Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i> Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i> Unlimited Parts Replacement</li>
                            <li><del>Unlimited Membership.</del></li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="pricing-card">
                    <div class="pricing-card-into color-bg3">
                        <i class="bx bx-paper-plane pricing-icon color-3"></i>
                        <h3 class="color-3">EXECUTIVE</h3>

                        <div class="price-rate">
                            <h2 class="color-3">$ 89.99</h2>
                            <span class="color-3">Yearly Package</span>
                        </div>

                        <ul>
                            <li><i class='bx bx-check'></i> Yearly Servicing Policy.</li>
                            <li><i class='bx bx-check'></i> Replacement Guarantee</li>
                            <li><i class='bx bx-check'></i> Unlimited Servicing.</li>
                            <li><i class='bx bx-check'></i> Unlimited Parts Replacement</li>
                            <li><i class='bx bx-check'></i> Unlimited Membership</li>
                        </ul>

                        <a routerLink="/" class="purchase-btn">PURCHASE NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Pricing Area End -->