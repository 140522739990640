<!-- Start Inner Banner -->
<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Sign Up</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> Sign Up </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Sign Up Area -->
<div class="sign-up-area pt-100 pb-70">
    <div class="container">
        <div class="user-all-form">
            <div class="contact-form">
                <div class="section-title text-center">
                    <span class="span-bg">Sign Up</span>
                    <h2>Create an Account!</h2>
                </div>

                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Username">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input class="form-control" type="password" name="password" placeholder="Password">
                            </div>
                        </div>

                        <div class="col-lg-12 text-center">
                            <button type="submit" class="default-btn">Sign Up</button>
                        </div>

                        <div class="col-lg-12">
                            <p class="account-desc">Already have an account? <a routerLink="/sign-in">Sign In</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Sign Up Area End -->