import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-uploads',
  templateUrl: './view-uploads.component.html',
  styleUrls: ['./view-uploads.component.scss']
})
export class ViewUploadsComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<ViewUploadsComponent>,
  ) {
    console.log(`🚀 ~ file: view-uploads.component.ts:12 ~ ViewUploadsComponent ~ constructor ~ data:`, data)
  }
  public close(): void {
    this.dialogRef.close(false);
  }
}
