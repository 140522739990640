<div class="appointment-form">
    <div class="section-title">
        <h6>When do you need your solution estimate back?</h6>
    </div>
    <form [formGroup]="estimateHours">
        <div class="row">
            <!-- <div *ngFor="let hour of estimateHoursList" class="col-lg-4 col-sm-4">
                <div>
                    <input type="radio" formControlName="estimateHour" [value]="hour.value" (change)="saveHour($event, hour)">
                    <label for="{{hour.key}}"> &nbsp;{{hour.label}}</label> -->

                    <div *ngFor="let hour of estimateHoursList" class="col-lg-4 col-sm-4">
                        <input  type="radio" formControlName="estimateHour" [value]="hour.value" (change)="saveHour(hour)"> {{ hour.placeHolder}}  ${{ productPrice + hour.price | number: '1.2-2'}}
                    </div>


                <!-- </div>
            </div> -->
        </div>
    </form>
</div>