import { Component, Input, OnInit } from "@angular/core";
import { orderSubmitConst } from "../../../constants/order-submit";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { productsImages, productsSku } from "src/app/components/constants";
import { Subject, interval } from 'rxjs';
import { PausableObservable, pausable } from 'rxjs-pausable';

@Component({
    selector: 'order-submit',
    templateUrl: './order-submit.component.html',
    styleUrls: ['./order-submit.component.scss']
})

export class OrderSubmitComponent implements OnInit {

    orderSubmitConst = orderSubmitConst;
    @Input() estimateHour;
    @Input() dueDate;
    @Input() orderId;
    @Input() sku;
    @Input() productName;
    @Input() propertyAddress;
    productsImages = productsImages;
    productsSku = productsSku;
    calculationForm: FormGroup;
    isCalcaulate = false;

    paused = true;
    pausable: PausableObservable<number>;

    constructor(
        private router: Router,
        private _formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.calculationForm = this.createCalculationFormBuilder();
        this.shoot();
        this.pausable = interval(800)
            .pipe(pausable()) as PausableObservable<number>;
        this.pausable.subscribe(this.shoot.bind(this));
        this.pausable.pause();
        this.scrollDown();
        this.toggle()

        setTimeout(() => {
            this.toggle()
        }, 3000);
    }
    createCalculationFormBuilder() {
        return this._formBuilder.group({
            phoneCallQue: ['',],
            phoneCallAns: [''],
            constratorQue: [''],
            constratorAns: [''],
            quoteQue: [''],
            quoteAns: [''],
            total: [''],
        });
    }
    onGoBack() {
        this.router.navigate(['/faq']);
    }

    calculate() {
        this.isCalcaulate = true;

        this.calculationForm.get('phoneCallAns').setValue(parseFloat(this.calculationForm.value.phoneCallQue) * 2);
        this.calculationForm.get('constratorAns').setValue(parseFloat(this.calculationForm.value.constratorQue) * 3);
        this.calculationForm.get('quoteAns').setValue(parseFloat(this.calculationForm.value.quoteQue) * 15);

        this.calculationForm.get('total').setValue(parseFloat(this.calculationForm.value.phoneCallAns +
            this.calculationForm.value.constratorAns + (this.calculationForm.value.quoteAns / 60)));

        this.toggle();

        setTimeout(() => {
            this.toggle();
        }, 3000);

    }

    reCalculate() {
        // this.toggle();
        this.isCalcaulate = false;
        this.calculationForm.reset()
    }

    toggle() {
        if (this.paused) {
            this.pausable.resume();
        } else {
            this.pausable.pause();
        }
        this.paused = !this.paused;
    }

    shoot() {
        try {
            this.confetti({
                angle: this.random(60, 120),
                spread: this.random(10, 50),
                particleCount: this.random(40, 50),
                origin: {
                    y: 0.6
                }
            });
        } catch (e) {
            // noop, confettijs may not be loaded yet
        }
    }

    random(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    confetti(args: any) {
        return window['confetti'].apply(this, arguments);
    }

    scrollDown() {
        window.scrollTo(0, 0);
    }
}


