import { Component, OnInit } from '@angular/core';
import { aboutConst } from '../../constants';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
    formValues = aboutConst;

    constructor() {}

    ngOnInit(): void {}
}
