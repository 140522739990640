import { Component, OnInit } from '@angular/core';
import { productContent, productIcons, productsImages, productsRoutes, productsSku, rightArrowIcon } from '../../constants';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'app-services-style-two',
  templateUrl: './services-style-two.component.html',
  styleUrls: ['./services-style-two.component.scss']
})
export class ServicesStyleTwoComponent implements OnInit {

  products: any = [];
  constructor(
    public productsService: ProductsService,
  ) { }

  productsDetails = productContent;
  productsImages = productsImages;
  productsRoutes = productsRoutes;
  productIcons = productIcons;
  rightArrowIcon = rightArrowIcon.arrowIcon;
  isLoading = false;
  request = productContent.request;
  quote = productContent.quote;
  productsSku = productsSku;


  ngOnInit(): void {
    this.listProducts();
  }

  listProducts() {
    this.isLoading = true;
    this.productsService.listProducts().then((productRes: any) => {
      this.products = productRes && productRes.data && productRes.data.allProducts && productRes.data.allProducts.nodes && productRes.data.allProducts.nodes.length > 0 ? productRes.data.allProducts.nodes : [];

      this.productsService.formatProductsData(this.products).then((formatRes) => {
        console.log("this.products", this.products)
        this.products = formatRes
        this.isLoading = false;

      }).catch((formatErr) => {
        this.isLoading = false;
      })
    }).catch((productErr) => {
      this.isLoading = false;
      this.products = [];
      console.log("🚀 ~ file: services-style-two.component.ts:27 ~ ServicesStyleTwoComponent ~ this.productsService.listProducts ~ productErr:", productErr)
    })
  }

}
