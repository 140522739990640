<!-- Start Inner Banner -->
<!-- <div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>FAQ</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> FAQ </li>
            </ul>
        </div>
    </div>
</div> -->
<!-- Inner Banner End -->

<!-- Start Faq Area -->
<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">{{ formValues.faq }}</span>
            <h2 class="h2-color2">{{ formValues.faQuestions }}</h2>
        </div>

        <div class="row align-items-center pt-45">
            <div class="col-lg-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li *ngFor="let faq of faqQuestionAnswerList" class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class='bx bx-chevron-down'></i>
                                {{ faq.faqQuestion }}
                            </a>
                            <div class="accordion-content">
                                <p>{{ faq.faqAnswer }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Faq Area End -->
<div class="about-area  pb-45">
    <div class="container">
        <app-testimonial></app-testimonial>
    </div>
</div>
<!-- <app-spekwo-served-images></app-spekwo-served-images> -->