import { Component, OnInit } from '@angular/core';
import { homeConst } from '../../constants';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    formValues = homeConst;

    constructor() {}

    ngOnInit(): void {}

    scrollDown() {
        window.scrollTo(0, document.body.scrollHeight);
    }
}
