<div class="headerClass" fxLayout="row">
    <p class="title">
        Uploded Image
    </p>
    <div>
        <button class="button_" mat-icon-button (click)="close()" style="color: white;" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

</div>

<div class="container" fxLayout="column">

    <div *ngFor="let img of data">
        <div class="image-container">
            <img [src]="img">
        </div>
    </div>
</div>