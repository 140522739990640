<!-- Start Inner Banner 
<div class="inner-banner inner-bg7">
    <app-product-banner
        [product_name]="currentProduct.length > 0 && currentProduct[0].productName ? currentProduct[0].productName : ''"></app-product-banner>
</div> -->
<!-- Inner Banner End -->

<!-- Start Services Details Area 
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <app-product-article
                        [productConst]="currentProduct.length > 0 ? currentProduct[0] : {}"></app-product-article>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">

                    <app-side-products [products]="products"></app-side-products>

                    <app-side-address></app-side-address>

                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Services Details Area End -->

<!-- Start Appointment Area -->
<div class="appointment-area pt-50 pb-20">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <main-spekwo-form
                    [currentProduct]="currentProduct.length > 0 ? currentProduct[0] : {}"></main-spekwo-form>
            </div>
        </div>
    </div>
</div>

<!-- Appointment Area End -->

<!-- Start Solution Estimate hours Area -->
<!-- <div class="appointment-area pt-20 pb-20">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <spekwo-ins-hours></spekwo-ins-hours>
            </div>
        </div>
    </div>
</div> -->
<!--  Solution Estimate hours End -->
<div class="about-area  pb-45">
    <div class="container">
        <app-testimonial></app-testimonial>
    </div>
</div>
<!-- <div><app-spekwo-served-images></app-spekwo-served-images></div> -->