<!-- Start Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog Right Sidebar</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blog Right Sidebar</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Blog Area -->
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="blog-card blog-card-bg">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-item1.jpg" alt="Images">
                                </a>
                                <span class="date">January 01, 2020</span>
                            </div>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Modern Technology Has Invested Globally</a></h3>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium laudantium dolorem eaque, quam accusantium eius! Possimus distinctio ipsum, eum debitis reprehenderit molestiae officiis? Itaque natus.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="blog-card blog-card-bg">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-item2.jpg" alt="Images">
                                </a>
                                <span class="date">August 01, 2020</span>
                            </div>
                            <div class="content">
                                <h3><a routerLink="/blog-details">International Car Trade Make a Deal for Us</a></h3>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium laudantium dolorem eaque, quam accusantium eius! Possimus distinctio ipsum, eum debitis reprehenderit molestiae officiis? Itaque natus.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="blog-card blog-card-bg">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-item3.jpg" alt="Images">
                                </a>
                                <span class="date">September 09, 2020</span>
                            </div>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Marketing Policy Added on the Policy Service</a></h3>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium laudantium dolorem eaque, quam accusantium eius! Possimus distinctio ipsum, eum debitis reprehenderit molestiae officiis? Itaque natus.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="blog-card blog-card-bg">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-item4.jpg" alt="Images">
                                </a>
                                <span class="date">September 20, 2020</span>
                            </div>
                            <div class="content">
                                <h3><a routerLink="/blog-details">How Do You Check to See If Your Car’s Oil Needs to Be Changed?</a></h3>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium laudantium dolorem eaque, quam accusantium eius! Possimus distinctio ipsum, eum debitis reprehenderit molestiae officiis? Itaque natus.</p>
                                <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-2" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-2" class="page-numbers">2</a>
                            <a routerLink="/blog-2" class="page-numbers">3</a>
                            <a routerLink="/blog-2" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="side-bar-wrap">
                    <div class="search-widget">
                        <form class="search-form">
                            <input type="search" class="form-control" placeholder="Search...">
                            <button type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Categories</h3>

                        <div class="side-bar-categories">
                            <ul>
                                <li>
                                    <a routerLink="/">Engine Servicing </a>
                                    <span>(1)</span>
                                </li>
                                <li>
                                    <a routerLink="/">Engin Oil and Filter</a>  
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a routerLink="/">Barke Service</a>
                                    <span>(3)</span>
                                </li>
                                <li>
                                    <a routerLink="/">Wheel Repair</a>
                                    <span>(4)</span>
                                </li>
                                <li>
                                    <a routerLink="/">Engine Diagonostic</a>
                                    <span>(5)</span>
                                </li>
                                <li>
                                    <a routerLink="/">Tyre Service</a>
                                    <span>(6)</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Recent Posts</h3>

                        <div class="widget-popular-post">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb">
                                    <span class="full-image cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Modern Technology Has Invested Globally</a></h4>
                                    <p>March 20, 2020</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb">
                                    <span class="full-image cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">International Car Trade Make a Deal for Us</a></h4>
                                    <p>June 11, 2020</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb">
                                    <span class="full-image cover bg3" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Marketing Policy Added on the Policy Service</a></h4>
                                    <p>August 10, 2020</p>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Tags</h3>

                        <ul class="side-bar-widget-tag">
                            <li><a routerLink="/blog-1">Atuo Repair</a></li>
                            <li><a routerLink="/blog-1">Barke</a></li>
                            <li><a routerLink="/blog-1">Service</a></li>
                            <li><a routerLink="/blog-1">Tyre</a></li>
                            <li><a routerLink="/blog-1">Engine</a></li>
                            <li><a routerLink="/blog-1">Oil Change</a></li>
                            <li><a routerLink="/blog-1">Repair</a></li>
                            <li><a routerLink="/blog-1">Wheel</a></li>
                            <li><a routerLink="/blog-1">Diagonostic</a></li>
                        </ul>
                    </div>

                    <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Area End -->