import { Component, Input } from '@angular/core';
import { productContent } from 'src/app/components/constants';

@Component({
  selector: 'app-product-banner',
  templateUrl: './product-banner.component.html',
  styleUrls: ['./product-banner.component.scss']
})
export class ProductBannerComponent {

  @Input() product_name: string;
  
}
