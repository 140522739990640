import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullSpekwoComponent } from './full-spekwo/full-spekwo.component';
import { ProductArticleComponent } from './product-article/product-article.component';
import { ProductBannerComponent } from './product-banner/product-banner.component';
import { SideProductsComponent } from './side-products/side-products.component';
import { SideAddressComponent } from './side-address/side-address.component';
import { PreListSpekwoComponent } from './pre-list-spekwo/pre-list-spekwo.component';
import { SpecificItemSpekwoComponent } from './specific-item-spekwo/specific-item-spekwo.component';
import { ProductsRoutingModule } from './products-routing.module';
import { MaterialsModule } from '../materials/materials.module';
import { MainSpekwoFormComponent } from './main-spekwo-form/main-spekwo-form.component';
import { SpekwoInsHoursComponent } from './spekwo-ins-hours/spekwo-ins-hours.component';
import { ViewUploadsComponent } from './view-uploads/view-uploads.component';
import { ProductsService } from './products.service';
import { OrderSubmitComponent } from './order-submit/order-submit.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { SpekwoServedImagesComponent } from './spekwo-served-images/spekwo-served-images.component';
import { AuthService } from 'src/app/service/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
    declarations: [
        FullSpekwoComponent,
        SpecificItemSpekwoComponent,
        PreListSpekwoComponent,
        SideAddressComponent,
        SideProductsComponent,
        ProductBannerComponent,
        ProductArticleComponent,
        MainSpekwoFormComponent,
        SpekwoInsHoursComponent,
        ViewUploadsComponent,
        OrderSubmitComponent,
        TestimonialComponent,
        SpekwoServedImagesComponent,
    ],
    imports: [
        CommonModule,
        ProductsRoutingModule,
        MaterialsModule,
        FlexLayoutModule
    ],
    entryComponents: [
        ViewUploadsComponent
    ],
    providers: [
        ProductsService,
        AuthService
    ],
    exports: [TestimonialComponent,
        SpekwoServedImagesComponent,
        FlexLayoutModule]
})
export class ProductsModule { }

