import { Component } from '@angular/core';
import { productContent } from 'src/app/components/constants';

@Component({
  selector: 'app-side-address',
  templateUrl: './side-address.component.html',
  styleUrls: ['./side-address.component.scss']
})
export class SideAddressComponent {

  contactConst = productContent.info;
  
}
