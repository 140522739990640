import { Injectable } from '@angular/core';

import { list_firm_with_limit_offset, list_markets_with_limit_offset, list_office_with_limit_offset, list_products_by_sku, list_products_without_offset, list_testimonials } from '../../gql/query/index';
import { CommonQueriesService } from 'src/app/common-queries.service';
import { create_invoice, create_order, get_breaking_news, get_orders_with_all_markets, get_schedules_with_all_markets, list_non_expire_coupon, list_status_by_name, update_coupon, update_order } from '../../gql/mutation/index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { apiCalls } from '../../constants/apiCalls';
import { AuditHistoryService } from './audit-history.service';




@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  apiCalls = apiCalls;

  constructor(
    private http: HttpClient,
    public commonQueriesService: CommonQueriesService,
    public auditHistoryService: AuditHistoryService,
  ) {
  }

  formatTheOrdersJson(productForms, updateProductForms) {
    return new Promise((resolve, reject) => {
      try {
        let totalPrice = productForms && productForms.paymentform && productForms.paymentform.totalPrice ? productForms.paymentform.totalPrice : 0;
        let estimateHour = productForms && productForms.paymentform && productForms.paymentform.estimateHour ? productForms.paymentform.estimateHour : '';
        let formattedJson = {
          id: updateProductForms && updateProductForms.id ? updateProductForms.id : null,//30867,
          total: `${totalPrice}.00`,
          number: updateProductForms && updateProductForms.id ? updateProductForms.id.toString() : null,//"30867"
          status: "pending",
          billing: {
            city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? productForms.addressForm.city : null,//"Salem",
            email: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? productForms.yourInfoForm.yourEmail : null,// "mohammedye@neosme.com",
            phone: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourCellNumber ? productForms.yourInfoForm.yourCellNumber : null,// "1234567890",
            state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? productForms.addressForm.state : null,//"Massachusetts",
            company: "",
            country: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.country ? productForms.addressForm.country : null,//"USA",
            postcode: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? productForms.addressForm.zip : null,//"636007",
            address_1: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.street ? productForms.addressForm.street : null,//"Paramanur",
            address_2: "",
            last_name: "",
            first_name: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? productForms.yourInfoForm.yourName : null//"Mohammed Test YN"
          },
          refunds: [],
          version: "3.5.10",
          cart_tax: "0.00",
          currency: "USD",
          shipping: {
            city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? productForms.addressForm.city : null,//"Salem",
            state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? productForms.addressForm.state : null,//"Massachusetts",
            company: "",
            country: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.country ? productForms.addressForm.country : null,//"USA",
            postcode: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? productForms.addressForm.zip : null,//"636007",
            address_1: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.street ? productForms.addressForm.street : null,//"Paramanur",
            address_2: "",
            last_name: "",
            first_name: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? productForms.yourInfoForm.yourName : null//"Mohammed Test YN"
          },
          cart_hash: "",
          date_paid: null,
          fee_lines: [],
          meta_data: [
            {
              id: 301169,
              key: "_shipping_email",
              value: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? productForms.yourInfoForm.yourEmail : null//"mohammedye@neosme.com"
            },
            {
              id: 301170,
              key: "_shipping_phone",
              value: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourCellNumber ? productForms.yourInfoForm.yourCellNumber : null,// "1234567890"
            },
            {
              id: 301171,
              key: "billing_myfield1",
              value: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourRole ? productForms.yourInfoForm.yourRole : null,// "Buyer Broker"
            },
            {
              id: 301172,
              key: "billing_myfield15",
              value: productForms && productForms.yourInfoForm && productForms.yourInfoForm.indRole ? productForms.yourInfoForm.indRole : null,// "Buyer"
            },
            {
              id: 301173,
              key: "billing_myfield23",
              value: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientName ? productForms.yourInfoForm.clientName : null,//"Salman CN"
            },
            {
              id: 301174,
              key: "billing_myfield2",
              value: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? productForms.addressForm.propertyAddress : null// "Paramanur SPA"
            },
            {
              id: 301175,
              key: "billing_myfield24",
              value: productForms.completeSeEmail.join(",")//"mohammedRCSE@neosme.com"
            },
            {
              id: 301176,
              key: "billing_agent_name",
              value: ""
            },
            {
              id: 301177,
              key: "billing_agent_email",
              value: ""
            },
            {
              id: 301178,
              key: "custom_invoice_client_firstname",
              value: "Mohammed Salman CFN"
            },
            {
              id: 301179,
              key: "custom_invoice_client_email",
              value: productForms && productForms.paymentform && productForms.paymentform.clientEmail ? productForms.paymentform.clientEmail : null,////"mohammedIP@neosme.com"
            },
            {
              id: 301180,
              key: "homeInspection",
              value: updateProductForms && updateProductForms.homeInsUrl ? updateProductForms.homeInsUrl : null,//"https://www.spekwo.com/wp-content/uploads/sampleNMS.pdf"
            },
            {
              id: 301181,
              key: "ddra",
              value: updateProductForms && updateProductForms.ddraUrl ? updateProductForms.ddraUrl : null,
            },
            {
              id: 301182,
              key: "wdoReport",
              value: updateProductForms && updateProductForms.wdoUrl ? updateProductForms.wdoUrl : null,
            },
            {
              id: 301183,
              key: "structEngReport",
              value: updateProductForms && updateProductForms.structEngUrl ? updateProductForms.structEngUrl : null,
            },
            {
              id: 301183,
              key: "wellReport",
              value: updateProductForms && updateProductForms.wellUrl ? updateProductForms.wellUrl : null,
            },
            {
              id: 301885,
              key: "item_list",
              value: productForms && productForms.specificItems && productForms.specificItems.length > 0 ? productForms.specificItems : []
            },
            {
              id: 259912,
              key: "picUpload",
              value: updateProductForms && updateProductForms.pics && updateProductForms.pics.length > 0 ? JSON.stringify(updateProductForms.pics) : ""
            },
            {
              id: 256753,
              key: "full-spekwo-items",
              value: productForms && productForms.fullSpekwoItems && productForms.fullSpekwoItems.length > 0 ? JSON.stringify(productForms.fullSpekwoItems) : ""
            },
          ],
          order_key: "wc_order_fOypAOxye2mQc",
          parent_id: 0,
          tax_lines: [],
          total_tax: "0.00",
          line_items: [
            {
              id: 5379,
              sku: productForms && productForms.paymentform && productForms.paymentform.productSku ? productForms.paymentform.productSku : '',//"HIQFULL",
              name: productForms && productForms.paymentform && productForms.paymentform.productName ? productForms.paymentform.productName : '',//"The speKwo",
              price: totalPrice,
              taxes: [],
              total: `${totalPrice}.00`,
              quantity: 1,
              subtotal: `${totalPrice}.00`,
              meta_data: [
                {
                  id: 57786,
                  key: "Delivery Time",
                  value: estimateHour + " (standard delivery)",// "24 hours (standard delivery)"
                },
                {
                  id: 57787,
                  key: "PDF - Home Inspection ",
                  value: updateProductForms && updateProductForms.homeInsUrl ? updateProductForms.homeInsUrl : null,//"https://www.spekwo.com/wp-content/uploads/sampleNMS.pdf"
                },
                {
                  id: 57788,
                  key: "PDF - SUPPORTING REPORT ",
                  value: ""
                },
                {
                  id: 57790,
                  key: "PDF - UNIQUE REPORT ",
                  value: ""
                },
                {
                  id: 301181,
                  key: "PDF - DDRA",
                  value: updateProductForms && updateProductForms.ddraUrl ? updateProductForms.ddraUrl : null,
                },
                {
                  id: 301182,
                  key: "wdoReport",
                  value: updateProductForms && updateProductForms.wdoUrl ? updateProductForms.wdoUrl : null,
                },
                {
                  id: 301183,
                  key: "structEngReport",
                  value: updateProductForms && updateProductForms.structEngUrl ? updateProductForms.structEngUrl : null,
                },
                {
                  id: 301183,
                  key: "wellReport",
                  value: updateProductForms && updateProductForms.wellUrl ? updateProductForms.wellUrl : null,
                },
              ],
              tax_class: "",
              total_tax: "0.00",
              product_id: 19533,
              subtotal_tax: "0.00",
              variation_id: 0
            }
          ],
          created_via: productForms && productForms.created_via ? productForms.created_via : null,
          customer_id: 0,
          coupon_lines: [],
          date_created: new Date(), //"2023-05-19T02:30:19",
          discount_tax: "0.00",
          shipping_tax: "0.00",
          customer_note: "",
          date_modified: new Date(), //"2023-05-19T02:30:19",
          date_paid_gmt: null,
          date_completed: null,
          discount_total: "0.00",
          payment_method: "bacs",
          shipping_lines: [],
          shipping_total: "0.00",
          transaction_id: "",
          date_created_gmt: new Date(), //"2023-05-19T06:30:19",
          date_modified_gmt: new Date(), //"2023-05-19T06:30:19",
          date_completed_gmt: null,
          prices_include_tax: false,
          customer_ip_address: "49.37.202.117",
          customer_user_agent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
          payment_method_title: "Direct bank transfer"
        }
        resolve(formattedJson)
      } catch {
        reject({})
        let payload = {
          ...productForms,
          ...updateProductForms
        }
        this.auditHistoryCreateFunCall(payload, 'Failed', "Order Formatted Failed From Order Site");
      }
    });
  }

  listProducts() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_products_without_offset, {}).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);

      }
    });
  }

  createOrder(payload) {
    let finalPayload = {
      input: {
        order: {
          ...payload
        }
      }
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.mutationQuery(create_order, finalPayload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("err", err)
          reject(err);
          this.auditHistoryCreateFunCall(payload, 'Failed', "Order Created Failed From Order Site");
        })
      } catch (error) {
        reject(error);
        this.auditHistoryCreateFunCall(payload, 'Failed', "Order Created Failed From Order Site");
      }
    });
  }

  updateOrder(payload, id) {
    let finalPayload = {
      input: {
        id: id,
        orderPatch: {
          ...payload
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.mutationQuery(update_order, finalPayload).subscribe((data) => {
          resolve(data);
          payload['id'] = id;
          this.auditHistoryCreateFunCall(payload, 'Success', "Order Created Success From Order Site");
        }, (err) => {
          console.log("err", err)
          reject(err);
          this.auditHistoryCreateFunCall(payload, 'Failed', "Order Created Failed at a time of update");
        })
      } catch (error) {
        reject(error);
        this.auditHistoryCreateFunCall(payload, 'Failed', "Order Created Failed at a time of update");
      }
    });
  }

  reportUpload(file, orderId, reportType) {
    let formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.reportsUpload;
      let params = new HttpParams();
      params = params.append('orderId', orderId);
      params = params.append('reportType', reportType);

      this.http.post(url, formData, { params: params })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        })
    })
  }

  // Upload company logo to serve
  fileUpload(payload, fileName: string, itemNo: string, orderId: string) {
    //Without base64 content using formData
    let formData = new FormData();
    formData.append('file', payload);

    return new Promise((resolve, reject) => {

      const url = this.apiCalls.uploadPreListQuoteImageUpload + '?fileName=' + fileName + '&itemNo=' + itemNo + '&orderId=' + orderId;

      this.http.post(url, formData)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        })
    })
  }

  stripeInvoiceToCustomer(payload) {

    const finalJson = JSON.stringify(payload);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.stripeInvToCustomer;
      let params = new HttpParams();
      params = params.append('finalJson', finalJson);

      this.http.get(url, { params: params })
        .subscribe((response: any) => {

          resolve(response)
        }, error => {
          reject(error);
        });
    })
  }

  createInvoiceForCustomer(payload) {
    let finalPayload = {
      input: {
        stripeinvoice: {
          ...payload
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.mutationQuery(create_invoice, finalPayload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  listMarkets(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_markets_with_limit_offset, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("lis market err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchAllMarkets() {
    return await new Promise(async (resolve, reject) => {
      let markets = [];
      let offset = 0;
      do {
        let payload = {
          offset: offset,
          limit: 100,
        };

        await this.listMarkets(payload).then((marketRes: any) => {
          if (marketRes && marketRes.data && marketRes.data.allMarkets && marketRes.data.allMarkets.nodes && marketRes.data.allMarkets.nodes.length > 0) {
            markets = markets.concat(marketRes.data.allMarkets.nodes);
            offset = offset + 10;
          } else {
            offset = null;
            resolve(markets);
          }
        }).catch((marketsError) => {
          console.log(" await this.listMarkets ~ marketsError:", marketsError)
          reject([]);
        });
      } while (offset != null)
    })
  }

  async formatMarketData(marketData) {
    return await new Promise((resolve, reject) => {
      let temp = [];
      for (let i = 0; i < marketData.length; i++) {
        let tempObj = {
          marketId: marketData[i] && marketData[i].marketId ? marketData[i].marketId : -1,
          marketName: marketData[i] && marketData[i].marketName ? decodeURIComponent(marketData[i].marketName) : null,
        };
        temp.push(tempObj)
      }
      resolve(temp);
    })
  }

  listFirms(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_firm_with_limit_offset, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("lis market err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchAllFirms(marketId) {
    return await new Promise(async (resolve, reject) => {
      let firms = [];
      let offset = 0;
      do {
        let payload = {
          offset: offset,
          limit: 100,
          marketId: marketId
        };

        await this.listFirms(payload).then((firmRes: any) => {
          if (firmRes && firmRes.data && firmRes.data.allMarketsMultiFirms && firmRes.data.allMarketsMultiFirms.nodes && firmRes.data.allMarketsMultiFirms.nodes.length > 0) {
            firms = firms.concat(firmRes.data.allMarketsMultiFirms.nodes);
            offset = offset + 10;
          } else {
            offset = null;
            resolve(firms);
          }
        }).catch((firmsError) => {
          console.log(" await this.listfirms ~ firmsError:", firmsError)
          reject([]);
        });
      } while (offset != null)
    })
  }

  async formatFirmsData(firmData) {
    return await new Promise((resolve, reject) => {
      let temp = [];
      for (let i = 0; i < firmData.length; i++) {
        let tempObj = {
          firmId: firmData[i] && firmData[i].firmId ? firmData[i].firmId : -1,
          firmsName: firmData[i] && firmData[i].firmByFirmId.firmsName ? decodeURIComponent(firmData[i].firmByFirmId.firmsName) : null,
          marketName: firmData[i] && firmData[i].marketByMarketId && firmData[i].marketByMarketId.marketName ? decodeURIComponent(firmData[i].marketByMarketId.marketName) : null,
          marketId: firmData[i] && firmData[i].marketId ? firmData[i].marketId : -1,
          marketsMultiFirmsId: firmData[i] && firmData[i].marketsMultiFirmsId ? firmData[i].marketsMultiFirmsId : -1,
        };
        temp.push(tempObj)
      }
      resolve(temp);
    })
  }


  listoffices(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_office_with_limit_offset, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("lis office err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchallOffices(firmId, marketId) {
    return await new Promise(async (resolve, reject) => {
      let offices = [];
      let offset = 0;
      do {
        let payload = {
          offset: offset,
          limit: 100,
          firmId: firmId,
          marketId: marketId
        };

        await this.listoffices(payload).then((officeRes: any) => {
          if (officeRes && officeRes.data && officeRes.data.allFirmsMultiOffices && officeRes.data.allFirmsMultiOffices.nodes && officeRes.data.allFirmsMultiOffices.nodes.length > 0) {
            offices = offices.concat(officeRes.data.allFirmsMultiOffices.nodes);
            offset = offset + 10;
          } else {
            offset = null;
            resolve(offices);
          }
        }).catch((officesError) => {
          console.log(" await this.listoffices ~ officesError:", officesError)
          reject([]);
        });
      } while (offset != null)
    })
  }

  async formatofficeData(officeData) {
    return await new Promise((resolve, reject) => {
      let temp = [];
      for (let i = 0; i < officeData.length; i++) {
        let tempObj = {
          officeId: officeData[i] && officeData[i].officeId ? officeData[i].officeId : -1,
          officesName: officeData[i] && officeData[i].officeByOfficeId && officeData[i].officeByOfficeId.officesName ? decodeURIComponent(officeData[i].officeByOfficeId.officesName) : null,
          firmId: officeData[i] && officeData[i].firmId ? officeData[i].firmId : -1,
          firmsName: officeData[i] && officeData[i].firmByFirmId && officeData[i].firmByFirmId.firmsName ? decodeURIComponent(officeData[i].firmByFirmId.firmsName) : null,
          firmsMultiOfficesId: officeData[i] && officeData[i].firmsMultiOfficesId ? officeData[i].firmsMultiOfficesId : -1,
        };
        temp.push(tempObj)
      }
      resolve(temp);
    });
  }

  async fetchCouponsByName(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_non_expire_coupon, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("list coupon getting error", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  async updateUsageLimit(id, usageLimit) {
    let payload = {
      input: {
        couponId: id,
        couponPatch: {
          usageLimit: usageLimit
        }
      }
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.mutationQuery(update_coupon, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("list coupon getting error", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getStripeCardToken(payload) {

    const finalJson = JSON.stringify(payload);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.stripeCardToken;
      let params = new HttpParams();
      params = params.append('finalJson', finalJson);

      this.http.get(url, { params: params })
        .subscribe((response: any) => {
          resolve(response)
        }, error => {
          reject(error);
        });
    })
  }

  paymentByCard(payload) {

    const finalJson = JSON.stringify(payload);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.paymentbyCard;
      let params = new HttpParams();
      params = params.append('finalJson', finalJson);

      this.http.get(url, { params: params })
        .subscribe((response: any) => {
          resolve(response)
        }, error => {
          reject(error);
        });
    })
  }

  formatProductsData(productData) {
    let products = []
    return new Promise((resolve, reject) => {
      for (let i = 0; i < productData.length; i++) {
        let temp = {
          productName: productData[i].productName ? decodeURIComponent(productData[i].productName) : "",
          productPrice: productData[i].productPrice ? productData[i].productPrice : 0,
          productDesc1: productData[i].productDesc1 ? decodeURIComponent(productData[i].productDesc1) : "",
          sku: productData[i].productDesc1 ? productData[i].sku : "",
          productId: productData[i].productId ? productData[i].productId : -1,
        };
        products.push(temp);
      }
      resolve(products);
    });
  }

  listProductsBySku(sku) {
    let payload = {
      sku: sku
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_products_by_sku, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);

      }
    });
  }

  fetchInitiateStatus() {
    let payload = {
      statusName: "initiate"
    };
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(list_status_by_name, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);

      }
    });
  }

  sentEmail(payload) {
    const finalJson = JSON.stringify(payload);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.orderConformEmail;
      let params = new HttpParams();
      params = params.append('finalJson', finalJson);

      this.http.get(url, { params: params })
        .subscribe((response: any) => {
          resolve(response)
        }, error => {
          reject(error);
        });
    })
  }

  sentOrderSubmitEmail(payload) {
    const finalJson = JSON.stringify(payload);
    return new Promise((resolve, reject) => {
      const url = this.apiCalls.orderSuccessEmail;
      let params = new HttpParams();
      params = params.append('finalJson', finalJson);

      this.http.get(url, { params: params })
        .subscribe((response: any) => {
          resolve(response)
        }, error => {
          reject(error);
        });
    })
  }

  auditHistoryCreateFunCall(savedDetails, status, description) {

    let auditLogPayload = {
      roleId: null,
      userId: null,
      orderId: Number(savedDetails.id),
      newData: savedDetails,
      oldData: null,
      auditType: "create_order",
      logsDesc: description,
      platform: "Spekwo Order Site",
      status: status
    };

    this.auditHistoryService.auditHistoryForCreateOrDeleteCall(auditLogPayload);
  }

  async fetchAllMarketsOrders() {
    return await new Promise(async (resolve, reject) => {
      let orders = [];
      let payload = {
        offset: 0,
        limit: 8,
      };
      await this.listAllOrderOfMarkets(payload).then((ordersRes: any) => {
        if (ordersRes && ordersRes.data && ordersRes.data.allOrders && ordersRes.data.allOrders.nodes && ordersRes.data.allOrders.nodes.length > 0) {
          orders = orders.concat(ordersRes.data.allOrders.nodes);
          resolve(orders);
        } else {
          resolve([]);
        }
      }).catch((marketsError) => {
        console.log(" await this.order markets based ~ orderError:", marketsError)
        reject([]);
      });
    })
  }

  async fetchAllMarketsSchedules() {
    return await new Promise(async (resolve, reject) => {
      let schedules = [];
      let payload = {
        offset: 0,
        limit: 3,
      };
      await this.listAllSchedulesOfMarkets(payload).then((scheduleRes: any) => {
        if (scheduleRes && scheduleRes.data && scheduleRes.data.listSchedulerequests && scheduleRes.data.listSchedulerequests.nodes && scheduleRes.data.listSchedulerequests.nodes.length > 0) {
          schedules = schedules.concat(scheduleRes.data.listSchedulerequests.nodes);
          resolve(schedules);
        } else {
          resolve([]);
        }
      }).catch((marketsError) => {
        console.log(" await this.order markets based ~ orderError:", marketsError)
        reject([]);
      });
    })
  }

  listAllOrderOfMarkets(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(get_orders_with_all_markets, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("lis market err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  listAllSchedulesOfMarkets(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(get_schedules_with_all_markets, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("lis market err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchAllBreakingNews() {
    return await new Promise(async (resolve, reject) => {
      let news = [];
      let offset = 0;
      do {
        let payload = {
          offset: offset,
          limit: 100,
        };
        await this.listAllBreakingNews(payload).then((newsRes: any) => {
          if (newsRes && newsRes.data && newsRes.data.allBreakingNews && newsRes.data.allBreakingNews.nodes && newsRes.data.allBreakingNews.nodes.length > 0) {
            news = news.concat(newsRes.data.allBreakingNews.nodes);
            offset = offset + 10;
          } else {
            offset = null;
            resolve(news);
          }
        }).catch((marketsError) => {
          console.log("listAllBreakingNews Error:", marketsError)
          reject([]);
        });
      } while (offset != null)
    })
  }

  listAllBreakingNews(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.commonQueriesService.listQuery(get_breaking_news, payload).subscribe((data) => {
          resolve(data)
        }, (err) => {
          console.log("listAllBreakingNews err", err)
          reject(err);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

}
