export const homeConst = {
    navBarAddress: 'Raleigh, NC, US · Charlotte, NC, US',
    navBarPhone: ' 866.646.0200',
    navBarMail: 'info@spekwo.com',
    navBarCustomer: 'speKwo Customers',
    navHeader1: 'How we work',
    navHeader2: 'About Us',
    footerSpekwo: '© speKwo 2023',
    footerRightsReserved: 'All Rights Reserved',
    footerPoweredBy: 'Powered by',
    footerNeosme: 'NeoSME',
    bannerHeader1: 'INSTANT QUOTES FOR YOUR RECENT HOME INSPECTION',
    bannerHeader2: 'Negotiations',
    bannerHeader3: 'Client Clarification & Assurance',
    bannerContent1:
        'Accurate Pricing and Reliable repairs completed on your timeline.',
    bannerContent_1:
        'All Quotes completed in 24-72 hours, 7 Days Per week',
    bannerContent2:
        'Whether you are a buyer or seller, having a certified speKwo will provide you the accuracy to negotiate your transaction..',
    bannerContent3:
        'Speak with one of our staff contractors to review your inspection report, get clarity, hear experience, and gain assurance..',
    purposeHeader: 'A Resource',
    purposeContent:
        'speKwo is designed to be a resource in your real estate transaction by giving you back time. We are a neutral party with an experienced team of tradesmen who answer the phones, write the quotes and complete the repairs.',
    convenientFastHeader: 'Convenient & Fast',
    convenientFastContent:
        'Our system allows you to upload your inspection report and receive your quote back in as little as 24 hours.',
    justTalkHeader: 'WE ANSWER THE PHONE',
    justTalkContent:
        'We know you need to reach us. Whether you are just needing to talk through the repair quote received or are at the closing table we are available to you by phone and text.',
    spotOnHeader: 'WE ARE LOCAL TO YOU',
    spotOnContent:
        'Founded in Charlotte, North Carolina, speKwo now serves Asheville, Greater Charlotte & SC area, Raleigh, the Triad, and upstate SC.',
    jonesHeader: "WE KNOW WHAT WERE TALKING ABOUT",
    jonesContent:
        'When you call our office all of the team members on the other end of the line have experience doing the exact repairs you are calling about. This means we can talk through each repair with you and your client.',
    hoverContent1: 'ACCURATE PRICING TO NEGOTIATE YOUR TRANSACTIONS',
    hoverContent2: 'NEVER HAVE TO COORDINATE MULTIPLE CONTRACTORS',
    hoverContent3: 'NO MORE CONCERN ABOUT DUE DILIGENCE PERIOD',
    testimonialContent: [
        {
            description: 'One of the most challenging parts of my job was just after the home inspection. speKwo has just removed the guess work by writing me one estimate for every item on the home inspection. Awesome I can stay focused on the next phase of transaction.',
            imageUrl: "assets/img/home-one/user-1.png"
        },
        {
            description: 'One of the most challenging parts of my job was just after the home inspection. speKwo has just removed the guess work by writing me one estimate for every item on the home inspection. Awesome I can stay focused on the next phase of transaction.',
            imageUrl: "assets/img/home-one/user-2.png"
        },
        {
            description: 'One of the most challenging parts of my job was just after the home inspection. speKwo has just removed the guess work by writing me one estimate for every item on the home inspection. Awesome I can stay focused on the next phase of transaction.',
            imageUrl: "assets/img/home-one/user-3.png"
        },
        {
            description: 'One of the most challenging parts of my job was just after the home inspection. Awesome I can stay focused on the next phase of transaction.',
            imageUrl: "assets/img/home-one/user-4.png"
        },
        {
            description: 'One of the most challenging parts of my job was just after the home inspection. speKwo has just removed the guess work by writing me one estimate for every item on the home inspection. Awesome I can stay focused on the next phase of transaction.',
            imageUrl: "assets/img/home-one/user-5.png"
        },
        {
            description: 'speKwo has just removed the guess work by writing me one estimate for every item on the home inspection.',
            imageUrl: "assets/img/home-one/user-6.png"
        },
        {
            description: 'speKwo has just removed the guess work by writing me one estimate for every item on the home inspection.',
            imageUrl: "assets/img/home-one/user-7.png"
        },
        {
            description: 'speKwo has just removed the guess work by writing me one estimate for every item on the home inspection.',
            imageUrl: "assets/img/home-one/user-8.png"
        }
    ],
    servedCompaniesHeader: 'Just a few of the companies speKwo has served',
    navBarContact: 'Contact',
    navBarAnswer: 'Answers',
    spekwoThought: "Time is a commodity, stop chasing contractors.",
    quotesTitle: "Quotes",
    quoteSubTitle: "Get your quote without leaving your desk. No driving to get price, no follow up phone calls. One request, one quote. We are so confident in the accuracy of our quote we will complete the repairs for the price quoted.",
    repairTitle: "Repairs",
    repairSubTitle: "All repairs (pre or post close) are completed by full time trandesmen who only work for speKwo. This means we control quality & scheduling. All repairs mean crawlspace work, Fixing Plumbing and Electrical, Interior and Exterior items or anything else you need done while our team is there.",
    clientsFeedback: "Words from our clients"
};
