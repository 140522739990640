import { Component, Input } from '@angular/core';
import { productsImages } from 'src/app/components/constants';

@Component({
  selector: 'app-product-article',
  templateUrl: './product-article.component.html',
  styleUrls: ['./product-article.component.scss']
})
export class ProductArticleComponent {

  productsImages = productsImages;
  @Input() productConst: any;


}
