<!-- Start Inner Banner -->
<div class="inner-banner inner-bg1 about-text">
    <div class="container">
        <div class="inner-title text-center">
            <!-- <h3>About Us</h3> -->
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul> -->
            <h2>{{ formValues.aboutHeader1 }}</h2>
            <p>{{ formValues.aboutContent1 }}</p>
            <h2>{{ formValues.aboutHeader2 }}</h2>
            <p>{{ formValues.aboutContent2 }}</p>
            <h2>{{ formValues.aboutHeader3 }}</h2>
            <p>{{ formValues.aboutContent3 }}</p>
            <h2>{{ formValues.aboutHeader4 }}</h2>
            <p>{{ formValues.aboutContent4 }}</p>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start About Area -->
<div class="about-area  pb-45">
    <div class="container">
        <app-testimonial></app-testimonial>
    </div>
</div>
<!-- <app-spekwo-served-images></app-spekwo-served-images> -->
<!-- <div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img-2">
                    <img src="assets/img/about/about-img2.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="span-bg">About Us</span>
                        <h2>We Have 20+ Years of Service Experience for You</h2>
                        <p>We have a lots of plan to make a good and best & one of the  most modern car for you. We have a good experience and a good image on the global sector.</p>
                    </div>
                    <ul>
                        <li><i class='bx bx-check-circle'></i> Customer Satisfaction</li>
                        <li><i class='bx bx-check-circle'></i> Latest & Modern Shop</li>
                        <li><i class='bx bx-check-circle'></i> Expertise Diagnostics</li>
                        <li><i class='bx bx-check-circle'></i> Fair Pricing</li>
                        <li><i class='bx bx-check-circle'></i> Expert Care</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- About Area End -->

<!-- Start Choose Area -->
<!-- <div class="choose-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Why Choose Us?</span>
            <h2>We Have the Plan for You to Give You Best </h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>
                    <div class="content">
                        <h3>Problem Solver</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>
                    <div class="content">
                        <h3>On-time Deliveries</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-card">
                    <div class="choose-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>
                    <div class="content">
                        <h3>Services by Experts</h3>
                        <p>We have the best service for you to make and this is one of the best service.</p>
                        <a routerLink="/service-details" class="read-more-btn"><i class="left-icon bx bx-right-arrow-alt"></i> Read More <i class="right-icon bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Choose Area End -->

<!-- Start Appointment Area -->
<!-- <div class="appointment-area appointment-bg pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="appointment-form appointment-form-bg">
                    <div class="section-title">
                        <h2>Book an Appointment</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing.</p>
                    </div>
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option value="">Select your services</option>
                                        <option value="">Car repair</option>
                                        <option value="">Car washes</option>
                                        <option value="">Car style</option>
                                        <option value="">Car working</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="appointment-img">
                    <img src="assets/img/appointment-img.jpg" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Appointment Area End -->

<!-- Start Team Area -->
<!-- <div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Our Team</span>
            <h2>We Have an Expert & Dedicated Team Member</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img1.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/SpeKwo-344051736111918/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>John Doe</h3>
                        <span>Founder - CEO</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img3.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/SpeKwo-344051736111918/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Jenifar Ambrina</h3>
                        <span>Co-Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/team/team-img4.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/SpeKwo-344051736111918/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Johan Smith</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Team Area End -->
