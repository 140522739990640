<!-- <div class="container mt-5">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
                <div
                    class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1 news">
                    <span class="d-flex align-items-center">&nbsp;Breaking News</span>
                </div>
                <marquee class="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();"
                    onmouseout="this.start();"><a href="#">{{tickerEvents}}</a>
                </marquee>
            </div>
        </div>
    </div>
</div> -->

<!-- marque breaking -->
<!-- <div class="row"> -->
<!-- <div class="col-lg-2 col-md-2 col-sm-2">
    <div class="col-lg-12 col-md-2 col-sm-2 news-title-sec">
        <div class="d-inline-block d-md-block  text-white text-center breaking-caret py-1 px-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="currentColor"
                class="bi bi-lightning-fill" viewBox="0 0 16 16">
                <path
                    d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
            </svg>
            <span class="d-none d-md-inline-block news-title">Breaking news</span>
        </div>
    </div>
</div> -->


<!--Breaking content-->
<!-- <div class="news-message-sec">
    <div class="col-lg-12 col-md-10 col-sm-10 breaking-box pt-2 pb-1">
        <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseleave="this.start();"
            scrollamount="15">
            <div class="news-message" href="#" [innerHTML]="tickerEvents"></div>
        </marquee>
    </div>
</div> -->
<!-- </div> -->
<!-- end marque breaking -->

<div class="onoffswitch3">
    <input type="checkbox" name="onoffswitch3" class="onoffswitch3-checkbox" id="myonoffswitch3" checked>
    <label class="onoffswitch3-label" for="myonoffswitch3">
        <span class="onoffswitch3-inner">
            <span class="onoffswitch3-active">
                <marquee scrollamount="10" class="scroll-text" [innerHTML]="tickerEvents">
                    <!-- <marquee scrollamount="10" class="scroll-text" *ngIf="newsData.length > 0"> -->


                    <!-- A {{newsData[0].agentrole}} from {{newsData[0].firmName}}
                    <img src="{{newsData[0].firmLogo}}" width="120" height="80" alt="Natural" />
                    {{newsData[0].content}} &nbsp; &nbsp; / / &nbsp; &nbsp;

                    A {{newsData[1].agentrole}} from {{newsData[1].firmName}}
                    <img src="{{newsData[1].firmLogo}}" width="120" height="80" alt="Natural" />
                    {{newsData[1].content}} &nbsp; &nbsp; / / &nbsp; &nbsp;

                    A {{newsData[2].agentrole}} from {{newsData[2].firmName}}
                    <img src="{{newsData[2].firmLogo}}" width="120" height="80" alt="Natural" />
                    {{newsData[2].content}} &nbsp; &nbsp; / / &nbsp; &nbsp;

                    A {{newsData[3].agentrole}} from {{newsData[3].firmName}}
                    <img src="{{newsData[3].firmLogo}}" width="120" height="80" alt="Natural" />
                    {{newsData[3].content}} &nbsp; &nbsp; / / &nbsp; &nbsp;

                    A {{newsData[4].agentrole}} from {{newsData[4].firmName}}
                    <img src="{{newsData[4].firmLogo}}" width="120" height="80" alt="Natural" />
                    {{newsData[4].content}} &nbsp; &nbsp; / / &nbsp; &nbsp; -->

                </marquee>
                <span class="onoffswitch3-switch">BREAKING NEWS</span>
            </span>
            <!-- <span class="onoffswitch3-inactive"><span></span></span> -->
        </span>
    </label>
</div>