<!-- Start Inner Banner -->
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Shop</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Shop</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Products Area End -->
<div class="product-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Shop</span>
            <h2>Our All Product</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img1.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$35.00</del></li>
                            <li>$30.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Brand New Tyre</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img2.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$35.00</del></li>
                            <li>$29.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Oil Filter</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img3.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li>$25.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Brake Disk</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img4.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li>$45.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Car Wheels</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img5.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$55.00</del></li>
                            <li>$50.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Petrol Pump</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img6.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$45.00</del></li>
                            <li>$40.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Spark Plug</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img7.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$65.00</del></li>
                            <li>$60.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Car Engine</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img8.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$75.00</del></li>
                            <li>$70.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Steering Wheel</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="single-product">
                    <a routerLink="/products-details">
                        <img src="assets/img/products/products-img9.jpg" alt="Images">
                    </a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$25.00</del></li>
                            <li>$20.00</li>
                        </ul>
                        <h3><a routerLink="/products-details">Headlights</a></h3>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To Cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/shop" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/shop" class="page-numbers">2</a>
                    <a routerLink="/shop" class="page-numbers">3</a>
                    <a routerLink="/shop" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Products Area End -->