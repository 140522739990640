import { Component, OnInit } from '@angular/core';
import { homeConst } from '../../constants';

@Component({
    selector: 'app-home-demo-one',
    templateUrl: './home-demo-one.component.html',
    styleUrls: ['./home-demo-one.component.scss'],
})
export class HomeDemoOneComponent implements OnInit {
    formValues = homeConst;

    constructor() {}

    ngOnInit(): void {}
}
