import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { estimateHours } from 'src/app/components/constants';

@Component({
  selector: 'spekwo-ins-hours',
  templateUrl: './spekwo-ins-hours.component.html',
  styleUrls: ['./spekwo-ins-hours.component.scss']
})
export class SpekwoInsHoursComponent {
  estimateHours: FormGroup;
  @Input() formTitle: string;
  estimateHoursList = estimateHours;
  @Output() selectedHourEmit: EventEmitter<any> = new EventEmitter();;
  @Input() productPrice;
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.estimateHours = this.formBuildwithFields()
  }

  saveHour(estimateHour) {
    this.selectedHourEmit.emit(estimateHour)
  }

  formBuildwithFields() {
    return this.formBuilder.group({
      estimateHour: [''],
    });
  }
}
