import { gql } from "apollo-angular";

export const CREATE_AUDIT_LOG = gql`
mutation createAuditLogs($input: CreateAuditLogInput!) {
  createAuditLog(input: $input) {
    auditLog {
      auditLogsId
      roleId
      userId
      orderId
      newData
      oldData
      auditType
      logsDesc
      platform
      status
      isDeletedYn
      createdAt
      updatedAt
    }
  }
}`;