export const orderSubmitConst = {
    complete: '100% completed',
    thankSubmit: 'Thank you for submitting. You have ordered a ',
    thankSubmit1: 'turn around on your quote. This means on',
    // "__/__/__  at __:__am"
    thankSubmit2: 'you will have your quote back!',
    issues: 'If you had any issues uploading you can email your home inspection to info@spekwo.com',
    howManyHrs: 'How many hours did you just save calling, meeting, and following up with contractors?',
    calc: 'Take a second and find out with our time saving calculator.',
    diffContractors: 'How many different contractors were you going to have to call?',
    meetContractors: 'How many times were you going to have to visit the property to meet contrators?',
    howManyTimeCall: 'How many times would you have called them to follow up on your quote?',
    savedTime: 'WOW, you saved  ',
    savedTime1: ' hours of your valuable time working with speKwo! ',
    timeAndEffect1: "“Focus is the art of elimination” - Seth Buechely",
    timeAndEffort: '"Time and effort can get you anything you want in the world. But nothing in the world can get you more time. - Matt Fox, The Hitchhiker Man"',
    calculate: 'Calculate time saved',
    reCalculate: 'Clear and Play again',
    goBack: 'Go Back to Site',
    yourOrderId: "Order Id #: ",
    propertyAddress: "Property Address: ",
    thankYou: "Thank you for placing your request for quote. You have requested a ",
    thankYouEnd: " turn-around.",
    orderDesc: "Don't worry, ",
    orderDesc1: "isn't that long.",
    orderDesc3: "you will have your",
    orderDesc2: "back no later then ",
    orderDesc4: "We know you are on a time line.",
    quiz: "Do you wanna know how much time you just saved calling multiple Contractors? Meeting them at the house? Following up with phone calls?",
    funQuiz: "Just for Fun … take our speKwo time saving quiz. ",
    toMeet: "If you requested an in-person review of your items we will contact you to schedule a time to meet.",
}
