<!-- Start Top Header
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-9">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-home-smile'></i>
                                </div>
                                <a href="#" target="_blank">{{ formValues.navBarAddress }}</a>
                            </li>
                            <li>
                                <span>Welcome to spekwo</span>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-phone-call'></i>
                                </div>
                                <a href="tel: 866.646.0200"> {{ formValues.navBarPhone }}</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info@spekwo.com">{{ formValues.navBarMail }}</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-cart-alt'></i>
                                </div>
                                <a href="#">Clear Cart</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-home-smile'></i>
                                </div>
                                <a href="https://dev.client.spekwo.com/">{{ formValues.navBarCustomer }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-3">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="https://www.facebook.com/SpeKwo-344051736111918/" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>  -->
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">

    <!-- Menu For Responsive -->
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logos/speKwo_logo.png" alt="Logo">
        </a>
    </div>

    <!-- Menu For Desktop -->
    <div class="main-nav nav-bar">

        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logos/speKwo_logo.png" alt="Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <!-- <a href="javascript:void(0)" class="nav-link">How we work <i
                                    class='bx bx-chevron-down'></i></a> -->

                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{ formValues.navHeader1 }}</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home Demo - One</a></li>

                                <li class="nav-item"><a routerLink="/home-demo-two" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Demo -
                                        Two</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{ formValues.navHeader2 }}</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/appointment" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Projects <i
                                            class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/projects" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Projects </a></li>

                                        <li class="nav-item"><a routerLink="/projects-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Projects Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming
                                        Soon</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item request-quote">
                            <a href="javascript:void(0)" routerLink="/spekwo-products"
                                class="nav-link request-quote-text">Request
                                Repair Quote</a>
                            <!-- <a href="javascript:void(0)" routerLink="/spekwo-products" class="nav-link">Request
                                Inspection Quote <i class='bx bx-chevron-down'></i></a> -->
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style One</a></li>

                                <li class="nav-item"><a routerLink="/spekwo-products" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Products</a>
                                </li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                            </ul> -->
                        </li>

                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->

                        <li class="nav-item"><a class="nav-link pointer" (click)="scrollDown()">{{
                                formValues.navBarContact }}</a></li>


                        <li class="nav-item">
                            <!-- <a href="javascript:void(0)" class="nav-link">Answers <i class='bx bx-chevron-down'></i></a> -->
                            <a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{ formValues.navBarAnswer }}</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog Style One</a></li>

                                <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog Style Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog
                                        Details</a></li>
                            </ul> -->
                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Shop</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Checkout</a>
                                </li>

                                <li class="nav-item"><a routerLink="/products-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Products
                                        Details</a></li>
                            </ul>
                        </li> -->

                    </ul>

                    <!-- <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <i class='search-btn bx bx-search'></i>
                            <i class='close-btn bx bx-x'></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i
                                                class="bx bx-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="add-cart-btn">
                                <a routerLink="/cart" class="cart-btn-icon">
                                    <i class='bx bx-cart-alt'></i>
                                    <span>0</span>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar Area -->