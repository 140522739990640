import { Component } from '@angular/core';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'spekwo-ticker',
  templateUrl: './web-ticker.component.html',
  styleUrls: ['./web-ticker.component.scss']
})
export class WebTickerComponent {
  title = 'Breaking News';
  tickerEvents = 'Current Breaking News is loading...';
  scheduleNews: string = "";
  orderNews: string = "";
  newsData: any = [];


  constructor(
    public productsService: ProductsService,
  ) {

  }

  ngOnInit() {
    this.getAllSchedulesByMarket();
    // this.getAllOrdersByMarket();
  }

  async getAllOrdersByMarket() {
    let finalString = "";
    await this.productsService.fetchAllMarketsOrders().then((orderRes: any) => {
      for (let i = 0; i < orderRes.length; i++) {
        let marketName = orderRes[i].firmByFirmId && orderRes[i].marketByMarketId.marketName ? decodeURIComponent(orderRes[i].marketByMarketId.marketName) : "";
        let firmName = orderRes[i].firmByFirmId && orderRes[i].firmByFirmId.firmsName ? decodeURIComponent(orderRes[i].firmByFirmId.firmsName) : "";
        let orderContentJson = orderRes[i].orderContent && orderRes[i].orderContent ? JSON.parse(orderRes[i].orderContent) : "";
        let line_items = orderContentJson && orderContentJson.line_items.length > 0 && orderContentJson.line_items[0] && orderContentJson.line_items[0].meta_data ? orderContentJson.line_items[0].meta_data : [];
        let deliveryTimeData = line_items.filter((metaData) => {
          return metaData.key === "Delivery Time";
        });
        let firmLogo = orderRes[i].firmByFirmId && orderRes[i].firmByFirmId.logoUrl ? orderRes[i].firmByFirmId.logoUrl : "";
        let estimatedHour = deliveryTimeData.length > 0 && deliveryTimeData[0].value ? deliveryTimeData[0].value.split("(")[0] : "";
        let agentrole = orderRes[i].agentrole && orderRes[i].agentrole ? orderRes[i].agentrole : "";
        let formatStr = `  <span>A ${agentrole} from ${firmName}  <img src="${firmLogo}"  width="7%" alt=""> just submitted a repair request for a ${estimatedHour} quote &nbsp&nbsp / / &nbsp&nbsp</span>`
        finalString = finalString + formatStr;
      }

      if (finalString) {
        this.tickerEvents = finalString;
      } else {
        this.tickerEvents = "There are no breaking news updates at the moment.";
      }
    }).catch((orderResError) => {
      console.log("orderRes", orderResError)
    })
  }

  async getAllSchedulesByMarket() {
    let finalString = "";
    await this.productsService.fetchAllMarketsSchedules().then(async (scheduleRes: any) => {
      await this.productsService.fetchAllMarketsOrders().then((orderRes: any) => {

        for (let i = 0; i < scheduleRes.length; i++) {
          let orderData = scheduleRes && scheduleRes[i] && scheduleRes[i].inspectionReportsSrcByScheduleRequestId &&
            scheduleRes[i].inspectionReportsSrcByScheduleRequestId.orderByOrderId ? scheduleRes[i].inspectionReportsSrcByScheduleRequestId.orderByOrderId : null;
          if (orderData) {
            let marketName = orderData.firmByFirmId && orderData.marketByMarketId.marketName ? decodeURIComponent(orderData.marketByMarketId.marketName) : "";
            let firmName = orderData.firmByFirmId && orderData.firmByFirmId.firmsName ? decodeURIComponent(orderData.firmByFirmId.firmsName) : "";
            let orderContentJson = orderData.orderContent && orderData.orderContent ? JSON.parse(orderData.orderContent) : "";
            let line_items = orderContentJson && orderContentJson.line_items.length > 0 && orderContentJson.line_items[0] && orderContentJson.line_items[0].meta_data ? orderContentJson.line_items[0].meta_data : [];
            let deliveryTimeData = line_items.filter((metaData) => {
              return metaData.key === "Delivery Time";
            });
            let firmLogo = orderData.firmByFirmId && orderData.firmByFirmId.logoUrl ? orderData.firmByFirmId.logoUrl : "";
            let estimatedHour = deliveryTimeData.length > 0 && deliveryTimeData[0].value ? deliveryTimeData[0].value.split("(")[0] : "";
            let agentrole = orderData.agentrole && orderData.agentrole ? orderData.agentrole : "";
            let formatStr = `<span>A ${agentrole} from ${firmName}  <img src="${firmLogo}"  width="120" height="80" alt=""""/> just Scheduled Repairs &nbsp; &nbsp; / / &nbsp; &nbsp;</span>`
            finalString = finalString + formatStr;
            let tempNewsObj = {
              agentrole: agentrole,
              firmName: firmName,
              firmLogo: firmLogo,
              content: `just Scheduled Repairs`
            };
            this.newsData.push(tempNewsObj)
          }
        }

        for (let i = 0; i < orderRes.length; i++) {
          let marketName = orderRes[i].firmByFirmId && orderRes[i].marketByMarketId.marketName ? decodeURIComponent(orderRes[i].marketByMarketId.marketName) : "";
          let firmName = orderRes[i].firmByFirmId && orderRes[i].firmByFirmId.firmsName ? decodeURIComponent(orderRes[i].firmByFirmId.firmsName) : "";
          let orderContentJson = orderRes[i].orderContent && orderRes[i].orderContent ? JSON.parse(orderRes[i].orderContent) : "";
          let line_items = orderContentJson && orderContentJson.line_items.length > 0 && orderContentJson.line_items[0] && orderContentJson.line_items[0].meta_data ? orderContentJson.line_items[0].meta_data : [];
          let deliveryTimeData = line_items.filter((metaData) => {
            return metaData.key === "Delivery Time";
          });
          let firmLogo = orderRes[i].firmByFirmId && orderRes[i].firmByFirmId.logoUrl ? orderRes[i].firmByFirmId.logoUrl : "";
          let estimatedHour = deliveryTimeData.length > 0 && deliveryTimeData[0].value ? deliveryTimeData[0].value.split("(")[0] : "";
          let agentrole = orderRes[i].agentrole && orderRes[i].agentrole ? orderRes[i].agentrole : "";
          let formatStr = `<span>A ${agentrole} from ${firmName}  <img src="${firmLogo}"  width="120" height="80" alt=""""/> just requested a ${estimatedHour} quote &nbsp; &nbsp; / / &nbsp; &nbsp;</span>`
          finalString = finalString + formatStr;
          let tempNewsObj = {
            agentrole: agentrole,
            firmName: firmName,
            firmLogo: firmLogo,
            content: `just requested a ${estimatedHour} quote`
          };
          this.newsData.push(tempNewsObj)
        }

        if (finalString) {
          this.tickerEvents = finalString;
          console.log("this.newsData", this.newsData);
        } else {
          this.tickerEvents = "There are no breaking news updates at the moment.";
        }
      }).catch((orderResError) => {
        console.log("orderRes", orderResError)
      })

      if (finalString) {
        this.tickerEvents = finalString;
      } else {
        this.tickerEvents = "There are no breaking news updates at the moment.";
      }
    }).catch((orderResError) => {
      console.log("orderRes", orderResError)
    })
  }

}
