import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullSpekwoComponent } from './full-spekwo/full-spekwo.component';
import { SpecificItemSpekwoComponent } from './specific-item-spekwo/specific-item-spekwo.component';
import { PreListSpekwoComponent } from './pre-list-spekwo/pre-list-spekwo.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { HomeDemoOneComponent } from '../home-demo-one/home-demo-one.component';


const routes: Routes = [
    { path: '', component: HomeDemoOneComponent },
    { path: 'full-spekwo', component: FullSpekwoComponent },
    { path: 'specific-item-spekwo', component: SpecificItemSpekwoComponent },
    { path: 'pre-list-spekwo', component: PreListSpekwoComponent },
    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductsRoutingModule { }
