import { Component, OnInit } from '@angular/core';
import { answerConst } from '../../constants';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
    formValues = answerConst;
    faqQuestionAnswerList = answerConst.faqList;

    constructor() {}

    ngOnInit(): void {}
}
