export const answerConst = {
    faq: 'FAQ',
    faQuestions: 'Frequently Asked Questions',
    faqList: [
        {
            faqQuestion:
                'How can you give me an estimate without seeing the problem?',
            faqAnswer:
                'We have over 30 years experience in the residential construction and remodeling industries and have reviewed and quoted over 2,000 reports in the last 8 years.  In addition to our vast knowledge and experience, we have ample resources available to us to be able to accurately quote the items on your home inspection.  We take into consideration the cost of labor, materials and the variance of these costs in geography.',
        },
        {
            faqQuestion: `Why can't the home inspector just give me quotes?`,
            faqAnswer:
                'In most states, it is not legal or ethical for a home inspector to quote the items they just flagged.',
        },
        {
            faqQuestion: 'What if I want the work done that was quoted?',
            faqAnswer:
                'speKwo has a list of approved tradesmen in all the various trades  most often one tradesmen can handle everything on the list.   To schedule the work simply login into your dashboard with the unique ID provided on your solution estimate.',
        },
        {
            faqQuestion: 'Will speKwo present to my realtors office?',
            faqAnswer:
                'Yes, we are happy to present to your office.  This is a 15 minute rapid and interactive presentation.  This is not a sales pitch but a presentation offering valuable tools in working through home inspections.',
        },
        {
            faqQuestion:
                'What if I want someone to physically come out and look at the property?',
            faqAnswer:
                'We are happy to schedule a walk through with one of our general contractors. They will be able to walk through and discuss any item on the inspection report with a deep level of experience and understanding.',
        },
        {
            faqQuestion: 'Are you home inspectors?',
            faqAnswer:
                'No, we are not home inspectors.   We are a group of experts in every field of the residential building and remodeling industry.',
        },
        {
            faqQuestion:
                'What are the qualifications of your staff providing the estimates?',
            faqAnswer:
                'Our team consists of experts in every field; structural, plumbing, electric, hvac, roofing, indoor repairs, outdoor repairs, and more.',
        },
        {
            faqQuestion:
                'How does speKwo decide how much to estimate for each repair/replace item?',
            faqAnswer:
                'We use price lists from professional contractors, average costs of labor, and estimated time needed to complete that type of task to come to an average cost to complete the work required.  Our pricing does not look to weigh in favor of the buyer or seller but remain neutral and reasonable.',
        },
        {
            faqQuestion: 'How does your team review so many reports?',
            faqAnswer:
                'Our proprietary software allows us to quickly and accurately review and quote countless reports each month.  Each report is manually reviewed by our department experts, approved, and then sent out to you.',
        },
        {
            faqQuestion: 'Is “speKwo” a word?',
            faqAnswer:
                'Yes! speKwo is a noun and a verb.  The origin of the word is rooted in the word “inspection” and the word “quote”. If you were to use in a sentence you would say: “Just speKwo it and you will have numbers back in no time!” OR “I have no idea what the inspector is talking about; let’s check the speKwo”.',
        },
        {
            faqQuestion: 'Is the cost of my speKwo quote refundable?',
            faqAnswer:
                'YES.  For any work completed by speKwo exceeding $500 the whole amount of your speKwo cost will be refunded back to the card used to pay for the quote 10 days after your final invoice has been paid.',
        },
    ],
};
