<div class="section-title text-center  pb-45">
    <h2>{{ formValues.clientsFeedback }}</h2>
</div>

<div class="testimonials-area testimonials-bg  pt-10 pb-10 testimonials-view">
    <div class="testimonials-slider pt-1 owl-carousel owl-theme">
        <div class="testimonials-item" *ngIf="allTestimonials[0]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[0].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[0].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="testimonials-item" *ngIf="allTestimonials[1]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[1].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[1].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[2]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[2].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[2].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[3]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[3].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[3].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[4]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[4].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[4].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[5]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[5].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[5].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[6]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[6].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[6].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[7]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[7].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[7].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[8]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[8].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[8].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[9]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[9].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[9].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-item" *ngIf="allTestimonials[10]">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-6">
                    <div class="testimonials-content">
                        <p>{{ allTestimonials[10].description }}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="testimonials-img">
                        <img [src]="allTestimonials[10].imageUrl" alt="Images">

                        <div class="quote-text">
                            <i class='bx bxs-quote-left'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>