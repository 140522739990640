<div class="section-title text-center pt-45 pb-45">
    <h2>{{ formValues.servedCompaniesHeader }}</h2>
</div>
<div class="container">
    <div class="row pt-45">
        <div class="col-lg-3 col-sm-6">
            <div class="achievements-card">
                <img src="assets/img/speKwo_logo_final-3.png" alt="Images">
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="achievements-card">
                <img src="assets/img/logo1.png" alt="Images">
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="achievements-card">
                <img src="assets/img/logo2.png" alt="Images">
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="achievements-card">
                <img src="assets/img/logo3.png" alt="Images">
            </div>
        </div>
    </div>
</div>
