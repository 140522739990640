import { Injectable } from '@angular/core';
import { Apollo } from "apollo-angular";


@Injectable({
  providedIn: 'root'
})
export class CommonQueriesService {

  constructor(public apollo: Apollo) {

  }

  listQuery(query, variable) {
    return this.apollo.query({
      query: query,
      variables: variable,
      fetchPolicy: 'network-only',
    })
  }

  mutationQuery(query, variable) {
    return this.apollo.mutate({
      mutation: query,
      variables: variable,
      fetchPolicy: 'network-only',
    })
  }
}
