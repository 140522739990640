import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { productContent } from '../../../constants/index'
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../products.service';
import _ from 'lodash';
@Component({
  selector: 'app-full-spekwo',
  templateUrl: './full-spekwo.component.html',
  styleUrls: ['./full-spekwo.component.scss']
})
export class FullSpekwoComponent {

  productSku: string;
  products: any = [];
  currentProduct = [];

  constructor(
    private route: ActivatedRoute,
    public productsService: ProductsService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.productSku = params && params.product ? params.product : null;
    });
  }

  ngOnInit() {
    this.getOrderBySku();
  }

  getOrderBySku() {
    this.productsService.listProducts().then(async (productRes: any) => {
      this.products = productRes && productRes.data && productRes.data.allProducts && productRes.data.allProducts.nodes && productRes.data.allProducts.nodes.length > 0 ? productRes.data.allProducts.nodes : [];
      await this.productsService.formatProductsData(this.products).then((formatRes) => {
        this.products = formatRes;
        this.currentProduct = _.filter(
          this.products, (product) => {
            return product.sku === this.productSku;
          }
        );
      }).catch((formatErr) => {

      })
    }).catch((productErr) => {
      console.log("fetch product by sku getting error")
    })
  }

}
