<!-- Start Inner Banner 
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{productsDetails.products.title}}</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>{{productsDetails.products.title}}</li>
            </ul>
        </div>
    </div>
</div>-->
<!-- Inner Banner End -->

<!-- Start Services Area  -->
<div class="service-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title text-center">
            <span class="span-bg">{{productsDetails.products.title}}</span>
            <h2>{{productsDetails.products.ourTeam}}</h2>
            <p>{{productsDetails.products.ourTeamContent}}</p>
        </div> -->


        <div *ngFor="let product of products" fxLayout="column" class="product-row">
            <div *ngIf="product.sku == productsSku.fullSpekwo" replaceUrl="true"
                [routerLink]="productsRoutes[product.sku]" [queryParams]="{product: product.sku}" fxLayout="row">
                <div class="box-1">
                    <img [src]="productsImages[product.sku]">
                </div>
                <div class="box-2">
                    <img class="imageIcon" [src]="productIcons[product.sku]">
                    <h3>
                        <p class="titleTag" style="color: black !important;">{{product.productName | uppercase}}
                            &nbsp;
                            ${{product.productPrice}}
                        </p>
                    </h3>

                    <p class="innerTag" [innerHTML]="product.productDesc1"> </p>
                    <div fxLayout="row">
                        <a class="request-text">{{request}} {{product.productName| uppercase}} {{quote}}</a>
                        <img [src]="rightArrowIcon" id="arrow-image-view">
                    </div>
                </div>
            </div>

            <div *ngIf="product.sku == productsSku.specificItem" replaceUrl="true"
                [routerLink]="productsRoutes[product.sku]" [queryParams]="{product: product.sku}" fxLayout="row">

                <div class="box-3">

                    <img class="imageIcon" [src]="productIcons[product.sku]">
                    <h3>
                        <p class="titleTag" style="color: black !important;">{{product.productName| uppercase}}
                            &nbsp;
                            ${{product.productPrice}}
                        </p>
                    </h3>

                    <p class="innerTag" [innerHTML]="product.productDesc1"> </p>
                    <div fxLayout="row">
                        <a class="request-text">{{request}} {{product.productName| uppercase}} {{quote}}</a>
                        <img [src]="rightArrowIcon" id="arrow-image-view">
                    </div>

                </div>
                <div class="box-4">
                    <img [src]="productsImages[product.sku]">

                </div>

            </div>

            <div *ngIf="product.sku == productsSku.preList" replaceUrl="true" [routerLink]="productsRoutes[product.sku]"
                [queryParams]="{product: product.sku}" fxLayout="row">
                <div class="box-1">
                    <img [src]="productsImages[product.sku]">
                </div>
                <div class="box-2">
                    <img class="imageIcon" [src]="productIcons[product.sku]">
                    <h3>
                        <p class="titleTag" style="color: black !important;">{{product.productName| uppercase}}
                            &nbsp;
                            ${{product.productPrice}}
                        </p>
                    </h3>

                    <p class="innerTag" [innerHTML]="product.productDesc1"> </p>
                    <div fxLayout="row">
                        <a class="request-text">{{request}} {{product.productName| uppercase}}
                            {{quote}}</a>
                        <img [src]="rightArrowIcon" id="arrow-image-view">
                    </div>
                </div>

            </div>
        </div>

        <div *ngIf="products.length === 0 && !isLoading" class="no-products">
            Products not found
        </div>

        <!-- <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let product of products">
                <div class="service-card" replaceUrl="true" [routerLink]="productsRoutes[product.sku]"
                    [queryParams]="{product: product.sku}">
                    <a>
                        <img [src]="productsImages[product.sku]" alt="Images">
                    </a>
                    <div class="content">
                        <i class="flaticon-wheel"></i>
                        <i class="flaticon-wheel service-icon-bg"></i>
                        <h3>
                            <p>{{product.productName}} &nbsp; ${{product.productPrice}}
                            </p>
                        </h3>
                        <p [innerHTML]="product.productDesc1"></p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="spinner-border text-primary loader-view" role="status" *ngIf="isLoading">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<!-- Services Area End -->
<div class="about-area  pb-45">
    <div class="container">
        <app-testimonial></app-testimonial>
    </div>
</div>
<!-- <div>
    <app-spekwo-served-images></app-spekwo-served-images>
</div> -->