import { Component, OnInit } from '@angular/core';
import { homeConst } from '../../../constants';

@Component({
    selector: 'app-spekwo-served-images',
    templateUrl: './spekwo-served-images.component.html',
    styleUrls: ['./spekwo-served-images.component.scss'],
})
export class SpekwoServedImagesComponent implements OnInit {
    formValues = homeConst;

    constructor() { }

    ngOnInit(): void { }
}
