import { Component, Input } from '@angular/core';
import { productContent, productsRoutes } from 'src/app/components/constants';

@Component({
  selector: 'app-side-products',
  templateUrl: './side-products.component.html',
  styleUrls: ['./side-products.component.scss']
})
export class SideProductsComponent {

  @Input() products;
  productContent = productContent;
  productsRoutes = productsRoutes

  constructor() {
  }
}
