<!-- Start Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Contact Area -->
<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <div class="section-title">
                        <span class="span-bg">CONTACT</span>
                        <h2>Keep in Touch</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                    </div>
                    
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-sidebar">
                    <h2>Our Contact Details</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                    <div class="row">
                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-home-smile'></i>
                                <div class="content">
                                    <h3>Address</h3>
                                    <p>2659 Autostrad St, </p>
                                    <span>London, Uk</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-phone-call'></i>
                                <div class="content">
                                    <h3>Phone</h3>
                                    <p><a href="tel: 866.646.0200"> 866.646.0200</a></p>
                                    <!-- <span><a href="tel:+215-123-8597">+215-123-8597</a></span> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bxs-envelope'></i>
                                <div class="content">
                                    <h3>Email</h3>
                                    <p><a href="mailto:info@spekwo.com">info@spekwo.com</a></p>
                                    <!-- <span><a href="mailto:hello@qeni.com">hello@qeni.com</a></span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Start Map Area -->
<div class="map-area">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9922.444473680396!2d-0.12427675970922974!3d51.55702951532976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b9fd875f4b1%3A0xc1f4679dc8b4bb01!2sHolloway%2C%20London%20N7%206PG%2C%20UK!5e0!3m2!1sen!2sbd!4v1601713197779!5m2!1sen!2sbd"></iframe>
    </div>
</div>
<!-- Map Area End -->