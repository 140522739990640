import { environment } from '../../../environments/environment';
export const site = {
    website: environment.website,
    apiHost: environment.apiHost,
}

export const host = site.apiHost;

export const apiCalls = {
    uploadPreListQuoteImageUpload: host + 'preListQuoteImageUpload.php',
    reportsUpload: host + 'reportsUpload.php',
    stripeInvToCustomer: host + 'invoiceCheck.php',
    stripeCardToken: host + 'stripeToken.php',
    paymentbyCard: host + 'paymentProcess.php',
    orderConformEmail: host + 'orderConformEmail.php',
    orderSuccessEmail: host + 'orderSuccessEmail.php',
}

export const common = {
    completed: "completed",
    uploadedPrelistImage: host + "preList/"
}