<!-- Start Inner Banner -->
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Sign In</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> Sign In </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Sign In Area -->
<div class="sign-in-area pt-100 pb-70">
    <div class="container">
        <div class="user-all-form">
            <div class="contact-form">
                <div class="section-title text-center">
                    <span class="span-bg">Sign In</span>
                    <h2>Sign In to Your Account!</h2>
                </div>

                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Username or Email">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input class="form-control" type="password" name="password" placeholder="Password">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 form-condition">
                            <div class="agree-label">
                                <input type="checkbox" id="chb1">
                                <label for="chb1">Remember Me</label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <a class="forget" href="#">Forgot my password?</a>
                        </div>

                        <div class="col-lg-12 text-center">
                            <button type="submit" class="default-btn">Sign In Now</button>
                        </div>

                        <div class="col-lg-12">
                            <p class="account-desc">Not a member? <a routerLink="/sign-up">Sign Up</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Sign In Area End -->