import { Injectable } from '@angular/core';

import { list_testimonials } from '../components/gql/query/index';
import { CommonQueriesService } from 'src/app/common-queries.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    testimonialsArray = new BehaviorSubject<Array<any>>([]);

    constructor(
        public commonQueriesService: CommonQueriesService,
    ) {
        this.getTestimonialsList();
    }

    getTestimonialsList() {
        this.listTestimonials().then((testimonialsRes) => {
            if (testimonialsRes && testimonialsRes['nodes'] && testimonialsRes['nodes'].length > 0) {
                this.setDataFormat(testimonialsRes['nodes']);
            }
            else {
                this.testimonialsArray.next([]);
            }
        }, () => {
            this.testimonialsArray.next([]);
        })
    }

    setDataFormat(inputResArr) {
        let temp = [];
        for (let i = 0; i < inputResArr.length; i++) {
            temp.push({
                description: inputResArr[i] && inputResArr[i]['description'] ? inputResArr[i]['description'] : "",
                imageUrl: inputResArr[i] && inputResArr[i]['imageUrl'] ? inputResArr[i]['imageUrl'] : "",
            })

            if (i + 1 == inputResArr.length) {
                this.testimonialsArray.next(temp);
            }
        }
    }

    listTestimonials() {
        return new Promise(async (resolve, reject) => {
            try {
                await this.commonQueriesService.listQuery(list_testimonials, {}).subscribe((response: any) => {
                    if (response && response.data && response.data.allBannerReviews) {
                        resolve(response.data.allBannerReviews);
                    }
                    else {
                        resolve(null);
                    }
                }, (err) => {
                    // console.log("err", err)
                    reject(err);
                })
            } catch (error) {
                reject(error);

            }
        });
    }

}
